import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//Search
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

// @material-ui/icons
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Check from "@material-ui/icons/Check";
import AddAlert from "@material-ui/icons/AddAlert";

//Authorization
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";

// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(styles);

export default function CambiarPassword(props) {
  const classes = useStyles();

  //   VARIABLES
  const [flag, setFlag] = useState("");
  const [txtOldPass, setTxtOldPass] = useState("");
  const [txtNewPass, setTxtNewPass] = useState("");
  const [txtConfirmPass, setTxtConfirmPass] = useState("");
  const [error, setError] = useState({});
  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function() {
      setTC(false);
    }, 6000);
  };

  const verify = () => {
    let errorLocal = {};
    !txtOldPass
      ? (errorLocal.oldPass = "Debe escribir su actual contraseña")
      : setFlag(200);
    txtNewPass.length <= 7
      ? (errorLocal.newPass =
          "Su nueva contraseña debe de tener minimo 8 caracteres")
      : setFlag(200);
    !txtNewPass
      ? (errorLocal.newPass = "Debe escribir una nueva contraseña")
      : setFlag(200);
    !txtConfirmPass
      ? (errorLocal.confirmPass = "Debe confirmar su nueva contraseña")
      : setFlag(200);
    txtNewPass !== txtConfirmPass
      ? (errorLocal.confirmPass =
          "Su confirmación no es igual que su nueva contraseña")
      : setFlag(200);
    setError(errorLocal);
    if (Object.keys(errorLocal).length === 0) {
      handlePassSubmit();
    }
  };

  const handlePassSubmit = async () => {
    // event.preventDefault();
    setAuthToken(localStorage.jwtToken);
    const decoded = jwt_decode(localStorage.jwtToken);

    const newUserPassToSave = {
      email: decoded.email,
      oldPass: txtOldPass,
      newPass: txtNewPass
    };

    await axios
      // .post("/api/users/cambiarPassword", newUserPassToSave)
      .post("https://epstool.com/api/users/cambiarPassword", newUserPassToSave)
      .then(res => {
        setError({});
        setTxtOldPass("");
        setTxtNewPass("");
        setTxtConfirmPass("");
        showNotification();
      })
      .catch(err => {
        setError(err.response.data);
      });
  };

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="success">
              <LockOpenIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}> Cambio de contraseña</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel className={classes.label}>
                  Contraseña Actual:
                </InputLabel>
                <CustomInput
                  labelText="Escribe tu contreña actual"
                  id="txtOldPass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      setTxtOldPass(event.target.value);
                    },
                    type: "text",
                    value: txtOldPass,
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <Danger>
                  {error.oldPass && (
                    <div className="invalid-feedback">{error.oldPass}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel className={classes.label}>
                  Nueva contraseña:
                </InputLabel>
                <CustomInput
                  labelText="Escribe tu nueva contraseña"
                  id="txtNewPass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      setTxtNewPass(event.target.value);
                    },
                    type: "text",
                    value: txtNewPass,
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <Danger>
                  {error.newPass && (
                    <div className="invalid-feedback">{error.newPass}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12}>
                <InputLabel className={classes.label}>
                  Confirmar nueva contraseña:
                </InputLabel>
                <CustomInput
                  labelText="Escribe nuevamente tu nueva contraseña"
                  id="txtConfirmPass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      setTxtConfirmPass(event.target.value);
                    },
                    type: "text",
                    value: txtConfirmPass,
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <Danger>
                  {error.confirmPass && (
                    <div className="invalid-feedback">{error.confirmPass}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <GridItem justify="flex-end" xs={12}>
              <Button fullWidth onClick={verify} color="success">
                Cambiar Contraseña
              </Button>
            </GridItem>
          </CardFooter>
        </Card>
      </GridContainer>
      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message="Tu contraseña a sido cambiada con éxito"
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
      ;
    </div>
  );
}
