import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import UserDashboard from "views/Dashboard/UserDashboard.js";
import LoginPage from "views/Pages/LoginPage.js";
import NuevoUsuario from "views/Usuario/crearUsuario.js";
import CambiarPassword from "views/Usuario/cambiarPassword";
import DetalleReporte from "views/Reporte/detalleReporte.js";
import CrearReporte from "views/Reporte/crearReporte.js";
import BMS from "views/Apps/Apps.js";
import Apps from "@material-ui/icons/Apps";
import Planilla from "views/Planillas/Planilla";
import SLA from "views/SLA/SLA";
import Encuestas from 'views/Encuestas/Encuestas';

// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import Timeline from "@material-ui/icons/Timeline";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import ListAltIcon from "@material-ui/icons/ListAlt";

// localStorage.setItem("test", 'test');
const roleUser = localStorage.userConfig;
console.log(roleUser);

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/reporte/:codigoReporte",
    name: "Reporte",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DetalleReporte,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 2,
    path: "/nuevoreporte",
    name: "Crear Reporte",
    rtlName: "لوحة القيادة",
    icon: AssignmentLateIcon,
    component: CrearReporte,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 3,
    path: "/charts",
    name: "Estadísticas",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/planilla",
    name: "Planilla",
    rtlName: "لوحة القيادة",
    icon: ListAltIcon,
    component: Planilla,
    layout: "/admin",
  },
  {
    path: "/SLA",
    name: "SLA",
    rtlName: "لوحة القيادة",
    icon: TimelapseIcon,
    component: SLA,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 3,
    path: "/nuevousuario",
    name: "Registro",
    rtlName: "الرسوم البيانية",
    icon: AssignmentInd,
    component: NuevoUsuario,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 3,
    path: "/encuestas",
    name: "Encuestas",
    rtlName: "الرسوم البيانية",
    icon: AssignmentInd,
    component: Encuestas,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/cambiarpassword",
    name: "Cambio Contraseña",
    rtlName: "الرسوم البيانية",
    icon: AssignmentInd,
    component: CambiarPassword,
    layout: "/admin",
  },
  {
    path: "/apps",
    name: "Aplicaciones DMS",
    rtlName: "لوحة القيادة",
    icon: Apps,
    component: BMS,
    layout: "/admin",
  },
  {
    collapse: true,
    invisible: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/apps",
        name: "Apps",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Apps,
        layout: "/auth",
      },
    ],
  },
];

var userDashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: UserDashboard,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/reporte/:codigoReporte",
    name: "Reporte",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DetalleReporte,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 2,
    path: "/nuevoreporte",
    name: "Crear Reporte",
    rtlName: "لوحة القيادة",
    icon: AssignmentLateIcon,
    component: CrearReporte,
    layout: "/admin",
  },
  {
    codigoPrivilegio: 3,
    path: "/charts",
    name: "Estadísticas",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/planilla",
    name: "Planilla",
    rtlName: "لوحة القيادة",
    icon: ListAltIcon,
    component: Planilla,
    layout: "/admin",
  },
  {
    path: "/SLA",
    name: "SLA",
    rtlName: "لوحة القيادة",
    icon: TimelapseIcon,
    component: SLA,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/cambiarpassword",
    name: "Cambio Contraseña",
    rtlName: "الرسوم البيانية",
    icon: AssignmentInd,
    component: CambiarPassword,
    layout: "/admin",
  },
  {
    collapse: true,
    invisible: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/apps",
        name: "Apps",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Apps,
        layout: "/auth",
      },
    ],
  },
];

// export default dashRoutes;
export default roleUser
  ? roleUser === "1"
    ? dashRoutes
    : userDashRoutes
  : dashRoutes;
