import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import CardIcon from "components/Card/CardIcon.js";

import ReactTable from "../../components/ReactTable/ReactTable.js";

// @material-ui/icons
import AnnouncementIcon from "@material-ui/icons/Announcement";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

function SLA(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let result;

    result = await axios(`https://epstool.com/api/reporte/sla`);
    // result = await axios(`http://localhost:5005/api/reporte/sla`);

    result.data[0].actividades.shift();

    setData(result.data[0].actividades);
  };

  // Lista Reportes
  let tbReportes;

  data.length >= 1
    ? (tbReportes = data.map((reporte) => {
        return {
          sla0: reporte.SLA0,
          sla1: reporte.SLA1,
          sla2: reporte.SLA2,
          sla3: reporte.SLA3,
        };
      }))
    : (tbReportes = []);

  let reportesLista;
  if (data == null) {
    reportesLista = null;
  } else {
    reportesLista = (
      <Grid
        justify="center"
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
      >
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <AnnouncementIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Tiempo establecido para asignación de tiquetes según categoría
                de SLA
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "SLA 0 (1 día)",
                    accessor: "sla0",
                  },
                  {
                    Header: "SLA 1 (1 - 3 días)",
                    accessor: "sla1",
                  },
                  {
                    Header: "SLA 2 (1 - 7 días)",
                    accessor: "sla2",
                  },
                  {
                    Header: "SLA 3 (1 - 15 días)",
                    accessor: "sla3",
                  },
                ]}
                data={tbReportes}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    );
  }

  return (
    <div>
      <GridContainer>{reportesLista}</GridContainer>
    </div>
  );
}

export default SLA;
