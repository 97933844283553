import React, { useState, useEffect } from "react";
import axios from "axios";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField2 from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import Grid from "@material-ui/core/Grid";
import CardIcon from "components/Card/CardIcon.js";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";
import Close from "@material-ui/icons/Close";

import ReactTable from "../../components/ReactTable/ReactTable.js";

// @material-ui/icons
import AnnouncementIcon from "@material-ui/icons/Announcement";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//DataPicker components
import InputLabel from "@material-ui/core/InputLabel";

// @mui
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

const CssTextField = styled(TextField2)({
  "& label.Mui-focused": {
    color: "#4caf50",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4caf50",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "current",
    },
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    "&.Mui-focused fieldset": {
      borderColor: "#4caf50",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Planilla(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [cantidadDias, setCantidadDias] = useState(new Date().getDate());
  const [value, setValue] = React.useState([null, null]);
  const [desde, setDesde] = React.useState();
  const [hasta, setHasta] = React.useState();
  const [loading, setLoading] = useState(false);

  const [rangeModal, setRangeModal] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDate = (e) => {
    e[0] !== null ? setDesde(e[0].getTime() / 1000) : setDesde("");

    e[1] !== null ? setHasta(e[1].getTime() / 1000) : setHasta("");
  };

  const handleDates = async (event) => {
    event.preventDefault();
    setLoading(true);

    let result;

    if (localStorage.userConfig === "2") {
      result = await axios.get(
        // `http://localhost:5005/api/reporte/user-planilla/${desde}/${hasta}/${
        //   jwt_decode(localStorage.jwtToken).id
        // }`
        `https://epstool.com/api/reporte/user-planilla/${desde}/${hasta}/${jwt_decode(localStorage.jwtToken).id}`
      );
    } else if (localStorage.userConfig === "1") {
      result = await axios.get(
        // `http://localhost:5005/api/reporte/user-planilla/${desde}/${hasta}`
        `https://epstool.com/api/reporte/user-planilla/${desde}/${hasta}`
      );
    }

    setData(result.data);
    setLoading(false);
  };

  const fetchData = async () => {
    let result;

    console.log(localStorage.userConfig);

    if (localStorage.userConfig === "2") {
      let decoded;
      try {
        decoded = jwt_decode(localStorage.jwtToken);
      } catch (error) {
        window.location.reload();
      }
      result = await axios(`https://epstool.com/api/reporte/user-planilla/${decoded.id}`);
    //   result = await axios(
    //     `http://localhost:5005/api/reporte/user-planilla/${decoded.id}`
    //   );

      result.data = [result.data[0]];
    } else if (localStorage.userConfig === "1") {
      result = await axios(`https://epstool.com/api/reporte/user-planilla`);
    //   result = await axios(`http://localhost:5005/api/reporte/user-planilla`);
    }

    setData(result.data);

    console.log(data);
  };

  // Lista Reportes
  let tbReportes;
  data.length >= 1
    ? (tbReportes = data.map((reporte) => {
        if (localStorage.userConfig === "2") {
          let decoded;
          try {
            decoded = jwt_decode(localStorage.jwtToken);
            reporte.user = decoded.name;
          } catch (error) {
            window.location.reload();
          }
        }

        return {
          name: reporte.user,
          pausados: reporte.pausados,
          asignados: reporte.asignados,
          sla0: reporte.SLA0,
          sla1: reporte.SLA1,
          sla2: reporte.SLA2,
          sla3: reporte.SLA3,
        };
      }))
    : (tbReportes = []);

  let reportesLista;
  if (data == null) {
    reportesLista = null;
  } else {
    reportesLista = (
      <Grid
        justify="center"
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
      >
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <AnnouncementIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Lista de Reportes de los últimos {cantidadDias} días
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Usuario",
                    accessor: "name",
                  },
                  {
                    Header: "Total",
                    accessor: "asignados",
                  },
                  {
                    Header: "Pausados",
                    accessor: "pausados",
                  },
                  {
                    Header: "SLA 0",
                    accessor: "sla0",
                  },
                  {
                    Header: "SLA 1",
                    accessor: "sla1",
                  },
                  {
                    Header: "SLA 2",
                    accessor: "sla2",
                  },
                  {
                    Header: "SLA 3",
                    accessor: "sla3",
                  },
                ]}
                data={tbReportes}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    );
  }

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>
            <h3 className={classes.cardIconTitle}>
              Planilla de los últimos {cantidadDias} días
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <GridContainer>
                  <GridItem xs md>
                    <InputLabel className={classes.label}>
                      Búsqueda por fecha
                    </InputLabel>
                    <br />
                    <GridContainer justify="center">
                      <GridItem xs={12} md={4}>
                        <CssTextField
                          fullWidth
                          label="Desde"
                          id="custom-css-outlined-input"
                          value={
                            desde
                              ? new Date(desde * 1000).toLocaleDateString()
                              : ""
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} md={4}>
                        <CssTextField
                          fullWidth
                          label="Hasta"
                          id="custom-css-outlined-input"
                          value={
                            hasta
                              ? new Date(hasta * 1000).toLocaleDateString()
                              : ""
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} md={4}>
                        {!desde || !hasta ? (
                          <Button
                            disabled
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>

                <br />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>{reportesLista}</GridContainer>
      {/* MODAL RANGE DATE */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={rangeModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setRangeModal(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setRangeModal(false);
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setState([item.selection]);
              handleDate([item.selection.startDate, item.selection.endDate]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </DialogContent>
        <DialogActions
          className={classes.modalFooter}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            onClick={() => {
              setRangeModal(false);
            }}
            color=""
            style={{ backgroundColor: "rgb(61, 145, 255)" }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Planilla;
