import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
//Modal
import Slide from "@material-ui/core/Slide";

//Autorizacion
import setAuthToken from "../../utils/setAuthToken";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormLabel from "@material-ui/core/FormLabel";
import AddAlert from "@material-ui/icons/AddAlert";

//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

export default function CrearReporte(props) {
  const [flag, setFlag] = useState("");

  const [txtComentario, setTxtComentario] = React.useState("");
  const [txtZona, setTxtZona] = React.useState("");
  const [txtEmail, setTxtEmail] = React.useState("");
  const [
    multipleSelectCategoria,
    setMultipleSelectCategoria
  ] = React.useState();
  const [errors, setErrors] = React.useState({});
  const [tc, setTC] = React.useState(false);

  const classes = useStyles();

  const handleMultipleCategoria = event => {
    setMultipleSelectCategoria(event.target.value);
  };

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleReporteSubmit = async event => {
    event.preventDefault();
    setAuthToken(localStorage.jwtToken);
    let arrUrl = [];
    let errorMessage = {};
    !txtComentario
      ? (errorMessage.comentario = "Debe escribir el comentario del tiquete")
      : setFlag(200);
    !txtZona
      ? (errorMessage.zona = "Debe escribir el lugar a reportar")
      : setFlag(200);
    !multipleSelectCategoria
      ? (errorMessage.categoria = "Selecione una categoria para el reporte")
      : setFlag(200);

    setErrors(errorMessage);

    if (Object.keys(errorMessage).length === 0) {
      const reporteToSave = {
        comentario: txtComentario,
        email: txtEmail,
        lugar: txtZona,
        otro_lugar: "",
        files: arrUrl,
        tipo: multipleSelectCategoria
      };
      await axios
        .post(`https://epstool.com/api/directorio/averia`, reporteToSave)
        // .post("http://localhost:5005/api/directorio/averia", reporteToSave)
        .then(res => {
          setErrors({});
          setTxtComentario("");
          setTxtZona("");
          setTxtEmail("");
          setMultipleSelectCategoria();
          showNotification();
        })
        .catch(error => {
          setErrors(error.response.data);
        });
    }
  };

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>
            <h3>Crear nuevo reporte</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Ingrese el detalle del comentario:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={9}>
                <CustomInput
                  multiline
                  labelText="Comentario"
                  id="txtComentario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: event => {
                      setTxtComentario(event.target.value);
                    },
                    type: "text",
                    value: txtComentario
                  }}
                />
                <Danger>
                  {errors.comentario && (
                    <div className="invalid-feedback">{errors.comentario}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Ingrese la ubicación a reportar:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={9}>
                <CustomInput
                  multiline
                  labelText="Zona"
                  id="txtZona"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: event => {
                      setTxtZona(event.target.value);
                    },
                    type: "text",
                    value: txtZona
                  }}
                />
                <Danger>
                  {errors.zona && (
                    <div className="invalid-feedback">{errors.zona}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Correo electrónico del solicitante:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={9}>
                <CustomInput
                  multiline
                  labelText="Correo electrónico"
                  id="txtEmail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: event => {
                      setTxtEmail(event.target.value);
                    },
                    type: "text",
                    value: txtEmail
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Seleccione la categoría:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8} md={9}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Categoría
                  </InputLabel>
                  <Select
                    value={multipleSelectCategoria}
                    onChange={handleMultipleCategoria}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "multipleSelectCategoria",
                      id: "multiple-select-categoria"
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value="A"
                    >
                      Infraestructura
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value="B"
                    >
                      Limpieza
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple
                      }}
                      value="C"
                    >
                      Cafetería
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.categoria && (
                      <div className="invalid-feedback">{errors.categoria}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={0} md={6}></GridItem>
              <GridItem xs={12} md={6}>
                <Button fullWidth color="success" onClick={handleReporteSubmit}>
                  Crear reporte
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message="Reporte creado con éxito"
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
