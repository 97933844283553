import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @mui
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
// import { DateRangePicker } from '@mui/x-date-pickers-pro';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import TextField2 from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

// import Typography from '@material-ui/core/Typography';
import CardIcon from "components/Card/CardIcon.js";
import TimerIcon from "@material-ui/icons/Timer";
import TimerOffIcon from "@material-ui/icons/TimerOff";

import ReactTable from "../../components/ReactTable/ReactTable.js";

//Search
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

// @material-ui/icons
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Forward from "@material-ui/icons/Forward";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import Avatar from "@material-ui/core/Avatar";
import MuiAlert from "@material-ui/lab/Alert";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import Close from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";

import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Sending from "../../common/sending.js";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const CssTextField = styled(TextField2)({
  "& label.Mui-focused": {
    color: "#4caf50",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4caf50",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "current",
    },
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    "&.Mui-focused fieldset": {
      borderColor: "#4caf50",
    },
  },
});

const useStyles = makeStyles(styles);

const badgeStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  shape: {
    width: 35,
    height: 35,
  },
  customBadge: {
    backgroundColor: "#00AFD7",
    color: "white",
  },
  CustomInput: {
    "& label.Mui-focused": {
      color: "green",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Dashboard(props) {
  const classes = useStyles();

  const classesBadge = badgeStyles();

  const { datas } = useLocation();

  const [data, setData] = useState({ reportes: [] });
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [search, setSearch] = React.useState("");
  const [multipleSelect, setMultipleSelect] = React.useState(["0", "1", "3"]);
  const [multipleSelectCategoria, setMultipleSelectCategoria] = React.useState([
    "1",
    "2",
    "3",
    "4",
  ]);
  const [multipleSelectTecnico, setMultipleSelectTecnico] = useState([]);
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [loading, setLoading] = useState(false);
  const [tiquetes, setTiquetes] = useState([]);

  const [value, setValue] = React.useState([null, null]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const [rangeModal, setRangeModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    let decoded;
    try {
      decoded = jwt_decode(localStorage.jwtToken);
      if (!localStorage.userConfig) {
        const flagUC = getLocalStorage();
        if (flagUC === 2) {
          window.location.reload();
        }
      }
    } catch (error) {
      window.location.reload();
    }
    let arrSelector = [];
    for (
      let index = 0;
      index < decoded.app[0].codigoCategoria.length;
      index++
    ) {
      const element = decoded.app[0].codigoCategoria[index];
      arrSelector.push(element.toString());
    }
    setAuthToken(localStorage.jwtToken);

    setUser(decoded);
    setMultipleSelectCategoria(arrSelector);
    fetchData();
    setLoading(false);
  }, []);

  const getLocalStorage = async () => {
    const idUser = jwt_decode(localStorage.jwtToken).id;
    const userConfig = await axios.get(
      // `http://localhost:5005/api/user-config/${idUser}`
      `https://epstool.com/api/user-config/${idUser}`
    );
    await localStorage.setItem("userConfig", userConfig.data[0].role.role);
    let decode2 = localStorage.userConfig;
    return decode2;
  };

  const fetchData = async () => {
    let result;

    if (
      datas !== undefined &&
      localStorage.fechaFiltro.split(",")[0] !== "" &&
      localStorage.fechaFiltro.split(",")[1] !== ""
    ) {
      let tempDate = localStorage.fechaFiltro.split(",");

      setValue(localStorage.fechaFiltro.split(","));

      if (tempDate[0] !== null && tempDate[1] !== null) {
        result = await axios.get(
          `https://epstool.com/api/reporte/date/${new Date(tempDate[0]) /
            1000}/${new Date(tempDate[1]) / 1000}`
          // `http://localhost:5005/api/reporte/date/${new Date(tempDate[0]) /
          // 1000}/${new Date(tempDate[1]) / 1000}`
        );
      }
    } else {
      result = await axios("https://epstool.com/api/reporte/all");
      // result = await axios("http://localhost:5005/api/reporte/all");
    }

    const resultUsers = await axios.get(
      `https://epstool.com/api/users/userstec`
      // `http://localhost:5005/api/users/userstec`
    );

    if (datas !== undefined) {
      if (localStorage.codigoFiltro !== "") {
        setSearch(localStorage.codigoFiltro);
      }
      if (localStorage.estadoFiltro !== "") {
        setMultipleSelect(localStorage.estadoFiltro.split(","));
      }
      if (localStorage.categoriaFiltro !== "") {
        setMultipleSelectCategoria(localStorage.categoriaFiltro.split(","));
      }
      if (localStorage.tecnicoFiltro) {
        setMultipleSelectTecnico(localStorage.tecnicoFiltro.split(","));
      }
    }

    setData(result.data);
    setTiquetes(result.data);
    setUsers(resultUsers.data);
  };

  const handleMultiple = (event) => {
    setMultipleSelect(event.target.value);
  };

  const handleMultipleCategoria = (event) => {
    setMultipleSelectCategoria(event.target.value);
  };

  const handleMultipleTecnico = (event) => {
    setMultipleSelectTecnico(event.target.value);
  };

  const handleDate = (e) => {
    e[0] !== null ? setDesde(e[0].getTime() / 1000) : setDesde("");

    e[1] !== null ? setHasta(e[1].getTime() / 1000) : setHasta("");
  };

  const handleTo = (e) => {
    setHasta(e.unix());
  };

  const handleDates = async (event) => {
    setLoading(true);
    event.preventDefault();
    const result = await axios.get(
       `https://epstool.com/api/reporte/date/${desde}/${hasta}`
     // `http://localhost:5005/api/reporte/date/${desde}/${hasta}`
    );
    setData(result.data);
    setTiquetes(result.data);
    setLoading(false);
  };

  const calculaHoras = (time) => {
    timeAssign = Math.round(
      ((Math.floor(new Date() / 1000) - time) * 1000) / 1000 / 60 / 60
    );

    if (timeAssign == 0) {
      timeAssign =
        Math.round(
          ((Math.floor(new Date() / 1000) - time) * 1000) / 1000 / 60
        ) + " minutos";
    } else {
      if (timeAssign > 48) {
        timeAssign = Math.round(timeAssign / 24) + " días";
      } else {
        timeAssign += " horas";
      }
    }
  };

  const calculaTiempoEsperado = (reporte) => {
    let tiempoPausa = 0;
    let fechaInicio = 0;

    fechaInicio = reporte.asignar[0].fecha;

    reporte.tiempos.forEach((element) => {
      if (reporte.fechaReabierto) {
        reporte.fechaReabierto < element.fechaPausa
          ? (tiempoPausa += element.fechaRetorno - element.fechaPausa)
          : (tiempoPausa += 0);
      }
    });

    switch (reporte.duracion) {
      case 1:
        return new Date(
          (fechaInicio + 24 * (60 * 60) + tiempoPausa) * 1000
        ).toLocaleString();
        break;
      case 2:
        return new Date(
          (fechaInicio + 24 * 3 * (60 * 60) + tiempoPausa) * 1000
        ).toLocaleString();
        break;
      case 3:
        return new Date(
          (fechaInicio + 24 * 7 * (60 * 60) + tiempoPausa) * 1000
        ).toLocaleString();
        break;
      case 4:
        return new Date(
          (fechaInicio + 24 * 15 * (60 * 60) + tiempoPausa) * 1000
        ).toLocaleString();
        break;
    }
  };

  const calculaStyle = (reporte) => {
    let fechaInicio = 0;
    let fechaDuracion = 0;
    const hoy = Math.floor(new Date() / 1000);

    reporte.asignar.length > 0
      ? (fechaInicio = reporte.asignar[reporte.asignar.length - 1].fecha)
      : (fechaInicio = 0);

    let tiempoPausa = 0;

    reporte.tiempos.forEach((element) => {
      if (reporte.fechaReabierto) {
        reporte.fechaReabierto < element.fechaPausa
          ? (tiempoPausa += element.fechaRetorno - element.fechaPausa)
          : (tiempoPausa += 0);
      }
    });

    let tTerminado = 0;
    reporte.tiempoATerminado
      ? (tTerminado = reporte.tiempoATerminado)
      : (tTerminado = 0);

    fechaDuracion =
      reporte.asignar.length > 0
        ? hoy - reporte.asignar[0].fecha - tiempoPausa + tTerminado
        : 0;

    fechaDuracion = Math.round(fechaDuracion / 60 / 60);

    switch (reporte.duracion) {
      case 1:
        if (fechaDuracion < 24) {
          return {
            backgroundColor: "#4caf50",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion == 24) {
          return {
            backgroundColor: "#ff9800",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion > 24) {
          return {
            backgroundColor: "#f44336",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        }
        break;
      case 2:
        if (fechaDuracion / 24 < 3) {
          return {
            backgroundColor: "#4caf50",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion / 24 == 3) {
          return {
            backgroundColor: "#ff9800",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion / 24 > 3) {
          return {
            backgroundColor: "#f44336",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        }
        break;
      case 3:
        if (fechaDuracion / 24 < 7) {
          return {
            backgroundColor: "#4caf50",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion / 24 == 7) {
          return {
            backgroundColor: "#ff9800",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion / 24 > 7) {
          return {
            backgroundColor: "#f44336",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        }
        break;
      case 4:
        if (fechaDuracion / 24 < 15) {
          return {
            backgroundColor: "#4caf50",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion / 15 == 3) {
          return {
            backgroundColor: "#ff9800",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        } else if (fechaDuracion / 15 > 3) {
          return {
            backgroundColor: "#f44336",
            color: "white",
            width: "100%",
            alignItems: "center",
          };
        }
        break;
      default:
        return { width: "100%", alignItems: "center" };
        break;
    }
  };

  // CARGAR TODOS LOS REPORTES
  let timeAssign = "";
  let reportes;
  if (data === null || loading === true) {
    // reportes = "Cargando...";
    reportes = (
      <div className={classes.load}>
        <Sending />
        <h4>Cargando reportes...</h4>
      </div>
    );
  } else {
    if (data.length > 0) {
      reportes = data
        .filter((reporte) => {
          return (
            reporte.codigoReporte.toString().indexOf(search.toLowerCase()) >= 0
          );
        })
        .filter((reporte) => {
          return (
            reporte.estado == multipleSelect[0] ||
            reporte.estado == multipleSelect[1] ||
            reporte.estado == multipleSelect[2] ||
            reporte.estado == multipleSelect[3]
          );
        })
        .filter((reporte) => {
          return (
            reporte.categoria == multipleSelectCategoria[0] ||
            reporte.categoria == multipleSelectCategoria[1] ||
            reporte.categoria == multipleSelectCategoria[2] ||
            reporte.categoria == multipleSelectCategoria[3]
          );
        })
        .filter((reporte) => {
          if (multipleSelectTecnico.length === 0) {
            return reporte;
          } else if (reporte.estado > 0) {
            if (reporte.asignar[0].asignacion) {
              for (let i = 0; i < users.length; i++) {
                for (let j = 0; j < reporte.asignar.length; j++) {
                  // console.log('name', (users.filter(user => user._id == multipleSelectTecnico[i]))[0].name)
                  if (
                    reporte.asignar[j].asignacion == multipleSelectTecnico[i]
                  ) {
                    return (
                      reporte.asignar[j].asignacion == multipleSelectTecnico[i]
                    );
                  } else {
                    let nameTemp =
                      users.filter(
                        (user) => user._id == multipleSelectTecnico[i]
                      ).length > 0
                        ? users.filter(
                            (user) => user._id == multipleSelectTecnico[i]
                          )[0].name
                        : "";
                    if (reporte.asignar[j].asignacion == nameTemp) {
                      return reporte.asignar[j].asignacion == nameTemp;
                    }
                  }
                }
              }
            }
          }
        })
        .map((reporte) => (
          <GridItem xs={12} sm={6} md={6} lg={3} key={reporte._id}>
            <Card key={reporte._id}>
              <CardHeader>
                <div className={classes.root}>
                  <Grid container>
                    <Grid item xs={9}>
                      <p>
                        Estado: {reporte.estado === 0 ? "Nuevo" : null}{" "}
                        {reporte.estado === 1 ? "En proceso" : null}{" "}
                        {reporte.estado === 2 ? "Terminado" : null}{" "}
                        {reporte.estado === 3 ? "En pausa" : null}
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      {reporte.estado === 3 ? (
                        <Grid item xs className={classesBadge.root}>
                          <Avatar
                            alt="Remy Sharp"
                            style={{
                              backgroundColor: "#ff9800",
                              color: "white",
                            }}
                          >
                            <TimerOffIcon />
                          </Avatar>
                        </Grid>
                      ) : null}
                      {reporte.estado === 1 &&
                      reporte.solicitudPausa.length > 0 ? (
                        reporte.solicitudPausa[0].estado === 1 ? (
                          <div className={classesBadge.root}>
                            <Badge
                              badgeContent={1}
                              color="primary"
                              classes={{ badge: classesBadge.customBadge }}
                            >
                              <PauseCircleFilledIcon
                                className={classesBadge.shape}
                                style={{ color: "#ff9800" }}
                              />
                            </Badge>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    {reporte.estado === 1 && reporte.asignar[0].fecha
                      ? calculaHoras(reporte.asignar[0].fecha)
                      : ""}
                    {reporte.estado === 1 ? (
                      <Grid item xs>
                        <p>El tiquete se asignó hace {timeAssign}</p>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </div>

                <h4>Reporte #{reporte.codigoReporte}</h4>
              </CardHeader>
              <CardBody>
                <p>
                  {reporte.detalle.length >= 50
                    ? `${reporte.detalle.substr(0, 50)} ...`
                    : reporte.detalle}
                </p>
                <Button
                  fullWidth
                  color="info"
                  className={classes.marginRight}
                  onClick={() => {
                    localStorage.codigoFiltro = search;
                    localStorage.estadoFiltro = multipleSelect;
                    localStorage.categoriaFiltro = multipleSelectCategoria;
                    localStorage.tecnicoFiltro = multipleSelectTecnico;
                    localStorage.fechaFiltro = value;

                    props.history.push(`reporte/${reporte.codigoReporte}`);
                  }}
                >
                  Detalles <KeyboardArrowRight className={classes.icons} />
                </Button>
              </CardBody>
              <CardFooter>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {reporte.categoria === 1 ? (
                        <div
                          className={classes.root}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Alert
                            severity="info"
                            style={
                              reporte.estado !== 2
                                ? calculaStyle(reporte)
                                : { width: "100%", alignItems: "center" }
                            }
                            icon={<TimerIcon />}
                          >
                            {reporte.duracion === 1
                              ? "SLA 0"
                              : "" || reporte.duracion === 2
                              ? "SLA 1"
                              : "" || reporte.duracion === 3
                              ? "SLA 2"
                              : "" || reporte.duracion === 4
                              ? "SLA 3"
                              : "Sin asignar"}
                          </Alert>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        Fecha creación:{" "}
                        {new Date(
                          reporte.fechaCreacion * 1000
                        ).toLocaleString()}
                      </p>
                      {reporte.estado === 1 && reporte.categoria === 1
                        ? `Tiempo esperado de finalización: ` +
                          calculaTiempoEsperado(reporte)
                        : ""}
                    </Grid>
                  </Grid>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ));
    } else {
      reportes = <h2>No hay reportes</h2>;
    }
  }
  //CARGAR PANEL DE Búsqueda SEGUN SUS PRIVILEGIOS
  let reportePanel;
  if (data === null) {
    reportePanel = null;
  } else {
    if (user.codigoPrivilegio < 2) {
      reportePanel = null;
    } else {
      reportePanel = (
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <InputLabel className={classes.label}>
                  Búsqueda por código
                </InputLabel>
                <CustomInput
                  labelText="Ingresar código de reporte"
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: search,
                    onChange: (event) => {
                      setSearch(event.target.value);
                    },
                    type: "text",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <InputLabel className={classes.label}>
                  Filtro de estado
                </InputLabel>
                <br />
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    multiple
                    value={multipleSelect}
                    onChange={handleMultiple}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "multipleSelect",
                      id: "multiple-select",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value="0"
                    >
                      Nuevo
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value="1"
                    >
                      En Proceso
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value="2"
                    >
                      Terminado
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value="3"
                    >
                      Pausado
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              {user.codigoPrivilegio < 3 ? null : (
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <InputLabel className={classes.label}>
                    Filtro de categoría
                  </InputLabel>
                  <br />
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      multiple
                      value={multipleSelectCategoria}
                      onChange={handleMultipleCategoria}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: "multipleSelectCategoria",
                        id: "multiple-select-categoria",
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value="1"
                      >
                        Infraestructura
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value="2"
                      >
                        Limpieza
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value="3"
                      >
                        Cafetería
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value="4"
                      >
                        Sin categoría
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              )}
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} md={12}>
                <GridContainer>
                  <GridItem xs md>
                    <InputLabel className={classes.label}>
                      Búsqueda por fecha
                    </InputLabel>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} md={4}>
                        <CssTextField
                          label="Desde"
                          id="custom-css-outlined-input"
                          value={
                            desde
                              ? new Date(desde * 1000).toLocaleDateString()
                              : ""
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} md={4}>
                        <CssTextField
                          label="Hasta"
                          id="custom-css-outlined-input"
                          value={
                            hasta
                              ? new Date(hasta * 1000).toLocaleDateString()
                              : ""
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} md>
                        <FormControl fullWidth>
                          <Datetime
                            onChange={handleTo}
                            timeFormat={false}
                            inputProps={{ placeholder: "Hasta" }}
                          />
                        </FormControl>
                      </GridItem> */}
                      <GridItem xs={12} md={4}>
                        {!desde || !hasta ? (
                          <Button
                            disabled
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <InputLabel className={classes.label}>
                      Búsqueda por Técnico
                    </InputLabel>
                    <br />
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        multiple
                        value={multipleSelectTecnico}
                        onChange={handleMultipleTecnico}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                          name: "multipleSelect",
                          id: "multiple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Selección de Técnico
                        </MenuItem>
                        {users.map((user, x) => (
                          <MenuItem
                            key={user._id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={user._id}
                          >
                            {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <br />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    }
  }
  const [states, setStates] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setStates({ ...state, [event.target.name]: event.target.checked });
  };

  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#52d869",
      "&$checked": {
        color: "#52d869",
      },
      "&$checked + $track": {
        backgroundColor: "#52d869",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  let switchVista;
  if (data === null) {
    switchVista = null;
  } else {
    switchVista = (
      <Grid
        justify="flex-end"
        container
        direction="row"
        justifycontent="flex-end"
        alignItems="center"
        style={{ marginRight: "50px" }}
      >
        <FormControlLabel
          control={
            <PurpleSwitch
              checked={states.checkedA}
              onChange={handleChange}
              name="checkedA"
            />
          }
          label={
            <Typography color="textPrimary">
              {states.checkedA ? "Vista Cuadrícula" : "Vista Lista"}
            </Typography>
          }
          labelPlacement="start"
          color="red"
        />
      </Grid>
    );
  }

  // Lista Reportes
  let tbReportes;
  data.length >= 1
    ? (tbReportes = data
        .filter((reporte) => {
          return (
            reporte.codigoReporte.toString().indexOf(search.toLowerCase()) >= 0
          );
        })
        .filter((reporte) => {
          return (
            reporte.estado == multipleSelect[0] ||
            reporte.estado == multipleSelect[1] ||
            reporte.estado == multipleSelect[2]
          );
        })
        .filter((reporte) => {
          return (
            reporte.categoria == multipleSelectCategoria[0] ||
            reporte.categoria == multipleSelectCategoria[1] ||
            reporte.categoria == multipleSelectCategoria[2] ||
            reporte.categoria == multipleSelectCategoria[3]
          );
        })
        .filter((reporte) => {
          if (multipleSelectTecnico.length === 0) {
            return reporte;
          } else if (reporte.estado > 0) {
            if (reporte.asignar[0].user) {
              for (let i = 0; i < users.length; i++) {
                if (reporte.asignar[0].user == multipleSelectTecnico[i]) {
                  return reporte.asignar[0].user == multipleSelectTecnico[i];
                }
              }
            }
          }
        })
        .map((reporte) => {
          let restado;

          switch (reporte.estado) {
            case 0:
              restado = "Nuevo";
              break;
            case 1:
              restado = "En proceso";
              break;
            case 2:
              restado = "Terminado";
              break;
            default:
              restado = "Desconocido";
          }

          if (reporte.email == "") {
            reporte.email = "Sin información";
          }

          return {
            id: reporte._id,
            codigo: reporte.codigoReporte,
            email: reporte.email,
            estado: restado,
            fecha_creacion: new Date(
              reporte.fechaCreacion * 1000
            ).toDateString(),
            detalle: reporte.detalle,
            actions: (
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    props.history.push(`reporte/${reporte.codigoReporte}`);
                  }}
                  color="success"
                  className="edit"
                >
                  <Forward />
                </Button>{" "}
              </div>
            ),
          };
        }))
    : (tbReportes = []);

  let reportesLista;
  if (data == null) {
    reportesLista = null;
  } else {
    reportesLista = (
      <Grid
        justify="center"
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <AnnouncementIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Lista de Reportes</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Código de reporte",
                    accessor: "codigo",
                  },
                  {
                    Header: "Email Solicitante",
                    accessor: "email",
                  },
                  {
                    Header: "Estado",
                    accessor: "estado",
                  },
                  {
                    Header: "Detalle",
                    accessor: "detalle",
                  },
                  {
                    Header: "Fecha creación",
                    accessor: "fecha_creacion",
                  },
                  {
                    Header: "Acciones",
                    accessor: "actions",
                  },
                ]}
                data={tbReportes}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    );
  }

  return (
    <div>
      <GridContainer>
        {reportePanel}
        {switchVista}
        {states.checkedA ? reportes : reportesLista}
        {/* MODAL RANGE DATE */}
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          open={rangeModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setRangeModal(false);
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                setRangeModal(false);
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setState([item.selection]);
                handleDate([item.selection.startDate, item.selection.endDate]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => {
                setRangeModal(false);
              }}
              color=""
              style={{ backgroundColor: "rgb(61, 145, 255)" }}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </div>
  );
}
