/*eslint-disable*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";

// excel
import ReactExport from "react-data-export";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// //Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import TextField2 from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

// import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Close from "@material-ui/icons/Close";

// core components
// import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Sending from "../../common/sending.js";

import ReactTable from "../../components/ReactTable/ReactTable.js";

//DataPicker components
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";

import setAuthToken from "../../utils/setAuthToken";

import { simpleBarChart, pieChart } from "variables/charts.js";

// @mui
import { styled } from "@mui/material/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//Excel req
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const CssTextField = styled(TextField2)({
  "& label.Mui-focused": {
    color: "#4caf50",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4caf50",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "current",
    },
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    "&.Mui-focused fieldset": {
      borderColor: "#4caf50",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Encuestas() {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [data, setData] = useState({ reportes: [] });
  const [encuesta, setEncuesta] = useState({});
  const [encuestaTxt, setEncuestaTxt] = useState();

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [desde, setDesde] = React.useState();
  const [dataPregunta1, setDataPregunta1] = useState();
  const [dataPregunta2, setDataPregunta2] = useState();
  const [dataPregunta3, setDataPregunta3] = useState();
  const [dataPregunta4, setDataPregunta4] = useState();
  const [dataPregunta5, setDataPregunta5] = useState();
  const [totalVotos, setTotalVotos] = useState();
  const [hasta, setHasta] = React.useState();
  const [cantidadDias, setCantidadDias] = useState(new Date().getDate());
  const [dataTablaSLA, setdataTablaSLA] = useState([]);
  const [multipleSelectTecnico, setMultipleSelectTecnico] = useState([]);
  const [rangeModal, setRangeModal] = useState(false);

  const userRole = localStorage.userConfig;

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const handleFrom = (e) => {
    setDesde(e.unix());
  };
  const handleTo = (e) => {
    setHasta(e.unix());
  };

  const handleDate = (e) => {
    e[0] !== null ? setDesde(e[0].getTime() / 1000) : setDesde("");

    e[1] !== null ? setHasta(e[1].getTime() / 1000) : setHasta("");
  };

  useEffect(() => {
    setLoading(true);
    setAuthToken(localStorage.jwtToken);
    const fetchData = async () => {
      let result;
      let resultPlanilla;
      let resultEncuesta;

      const hoy = new Date();

      // Primer día del mes actual
      const primerDiaDelMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
      // Último día del mes actual
      const ultimoDiaDelMes = new Date(
        hoy.getFullYear(),
        hoy.getMonth() + 1,
        0
      );

      // Función para formatear la fecha en el formato deseado
      function formatearFecha(fecha) {
        const anio = fecha.getFullYear();
        const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
        const dia = fecha
          .getDate()
          .toString()
          .padStart(2, "0");
        return `${anio}-${mes}-${dia}`;
      }

      // Almacenar las fechas formateadas en variables
      const primerDiaFormateado = formatearFecha(primerDiaDelMes);
      const ultimoDiaFormateado = formatearFecha(ultimoDiaDelMes);
      console.log(primerDiaFormateado);
      resultEncuesta = await axios(
        `https://dms-center.herokuapp.com/api/v1/satisfaction/form/statistics?startDate=${primerDiaFormateado}&endDate=${ultimoDiaFormateado}`
      );
      setEncuestaTxt(resultEncuesta.data);

      // Calcular la suma total de votos para question1
      const totalVotos = Object.values(resultEncuesta.data.question1).reduce(
        (acc, current) => acc + current,
        0
      );
      setTotalVotos(totalVotos);

      function formatQuestion(questionData) {
        return {
          labels: [
            "Muy insatisfecho",
            "Insatisfecho",
            "Regular",
            "Satisfecho",
            "Muy Satisfecho",
          ],
          series: [
            [
              questionData["1"],
              questionData["2"],
              questionData["3"],
              questionData["4"],
              questionData["5"],
            ],
          ],
        };
      }

      // Ahora, formatemos la pregunta 1 como ejemplo:
      const dataPregunta1 = formatQuestion(resultEncuesta.data.question1);
      const dataPregunta2 = formatQuestion(resultEncuesta.data.question2);
      const dataPregunta3 = formatQuestion(resultEncuesta.data.question3);
      const dataPregunta4 = formatQuestion(resultEncuesta.data.question4);
      const dataPregunta5 = {
        labels: ["Si", "No"],
        series: [
          [
            resultEncuesta.data.question5True,
            resultEncuesta.data.question5False,
          ],
        ],
      };

      setDataPregunta1(dataPregunta1);
      setDataPregunta2(dataPregunta2);
      setDataPregunta3(dataPregunta3);
      setDataPregunta4(dataPregunta4);
      setDataPregunta5(dataPregunta5);

      hoy.setDate(1);
      // hoy.setMonth(3);//quitar esta linea en produccion
    };
    fetchData();
    setLoading(false);
  }, []);

  const handleDates = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Función para convertir timestamp a fecha en formato deseado
    function timestampAFormatoFecha(timestamp) {
      const fecha = new Date(timestamp * 1000);
      const anio = fecha.getFullYear();
      const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const dia = fecha
        .getDate()
        .toString()
        .padStart(2, "0");
      return `${anio}-${mes}-${dia}`;
    }

    const desdeFormato = timestampAFormatoFecha(desde);
    const hastaFormato = timestampAFormatoFecha(hasta);

    console.log(desdeFormato);
    const resultEncuestaDates = await axios(
      `https://dms-center.herokuapp.com/api/v1/satisfaction/form/statistics?startDate=${desdeFormato}&endDate=${hastaFormato}`
      // `https://dms-center.herokuapp.com/api/v1/satisfaction/form/service/cafeteria/statistics?startDate=${desdeFormato}&endDate=${hastaFormato}`
    );

    setEncuestaTxt(resultEncuestaDates.data);

    function formatQuestion(questionData) {
      return {
        labels: [
          "Muy insatisfecho",
          "Insatisfecho",
          "Regular",
          "Satisfecho",
          "Muy Satisfecho",
        ],
        series: [
          [
            questionData["1"],
            questionData["2"],
            questionData["3"],
            questionData["4"],
            questionData["5"],
          ],
        ],
      };
    }

    // Ahora, formatemos la pregunta 1 como ejemplo:
    const dataPregunta1 = formatQuestion(resultEncuestaDates.data.question1);
    const dataPregunta2 = formatQuestion(resultEncuestaDates.data.question2);
    const dataPregunta3 = formatQuestion(resultEncuestaDates.data.question3);
    const dataPregunta4 = formatQuestion(resultEncuestaDates.data.question4);
    const dataPregunta5 = {
      labels: ["Si", "No"],
      series: [
        [resultEncuestaDates.data.question5True, resultEncuestaDates.data.question5False],
      ],
    };

    setDataPregunta1(dataPregunta1);
    setDataPregunta2(dataPregunta2);
    setDataPregunta3(dataPregunta3);
    setDataPregunta4(dataPregunta4);
    setDataPregunta5(dataPregunta5);

    // setCantidadDias((hasta - desde) / 86400);
    setLoading(false);
  };

  // Función para manejar el evento de dibujo y añadir los números
  const drawEvent = (data) => {
    if (data.type === "bar") {
      data.element._node.addEventListener("draw", function(event) {
        if (event.type === "bar") {
          var bar = event.element;
          var group = bar.parent;
          var texto = new Chartist.Svg("text");

          texto.text(event.value.y);
          texto.attr({
            x: event.x2 + 5,
            y: event.y1 + (bar.height() < 30 ? -10 : 5),
            "text-anchor": "middle",
          });

          group.append(texto);
        }
      });
    }
  };

  let graficoBar1;
  let graficoBar2;
  let graficoBar3;
  let graficoBar4;
  let graficoBar5;

  //   const detallesPregunta1 = encuestaTxt["Pregunta 1"];

  if (encuesta === undefined || loading === true || encuestaTxt === undefined) {
    // graficoPie = "Cargando...";
    graficoBar1 = (
      <div className={classes.load}>
        <Sending />
        <h4>Cargando reportes...</h4>
      </div>
    );
  } else {
    graficoBar1 = (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            ¿Cómo ha sido su experiencia con el servicio y la atención del
            personal de Newrest?
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={9} md={9}>
              <ChartistGraph
                data={dataPregunta1}
                type="Bar"
                options={simpleBarChart.options}
                listener={{
                  draw: drawEvent,
                }}
                // listener={colouredLinesChart.animation}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div>
                <ul>
                  {dataPregunta1.labels.map((label, index) => (
                    <li key={label}>
                      {label}: {dataPregunta1.series[0][index]} votos
                    </li>
                  ))}
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );

    graficoBar2 = (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {" "}
            ¿Cómo calificarías la rapidez del servicio a la hora de servir los
            alimentos?
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={9} md={9}>
              <ChartistGraph
                data={dataPregunta2}
                type="Bar"
                options={simpleBarChart.options}
                listener={{
                  draw: drawEvent,
                }}
                // listener={colouredLinesChart.animation}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div>
                <ul>
                  {dataPregunta2.labels.map((label, index) => (
                    <li key={label}>
                      {label}: {dataPregunta2.series[0][index]} votos
                    </li>
                  ))}
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );

    graficoBar3 = (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            Si alguna vez ha tenido problemas en el área de cajas, ¿cómo
            calificarías la resolución de dicha complicación?
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={9} md={9}>
              <ChartistGraph
                data={dataPregunta3}
                type="Bar"
                options={simpleBarChart.options}
                listener={{
                  draw: drawEvent,
                }}
                // listener={colouredLinesChart.animation}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div>
                <ul>
                  {dataPregunta3.labels.map((label, index) => (
                    <li key={label}>
                      {label}: {dataPregunta3.series[0][index]} votos
                    </li>
                  ))}
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );

    graficoBar4 = (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}> Si alguna vez ha tenido dudas sobre su deducción de planilla, ¿cómo calificarías la forma en que se aclaró?</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={9} md={9}>
              <ChartistGraph
                data={dataPregunta4}
                type="Bar"
                options={simpleBarChart.options}
                listener={{
                  draw: drawEvent,
                }}
                // listener={colouredLinesChart.animation}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div>
                <ul>
                  {dataPregunta4.labels.map((label, index) => (
                    <li key={label}>
                      {label}: {dataPregunta4.series[0][index]} votos
                    </li>
                  ))}
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );

    graficoBar5 = (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>¿Sabe a quién recurrir para tramitar una queja o sugerencia?</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={9} md={9}>
              <ChartistGraph
                data={dataPregunta5}
                type="Bar"
                options={simpleBarChart.options}
                listener={{
                  draw: drawEvent,
                }}
                // listener={colouredLinesChart.animation}
              />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div>
                <ul>
                  {dataPregunta5.labels.map((label, index) => (
                    <li key={label}>
                      {label}: {dataPregunta5.series[0][index]} %
                    </li>
                  ))}
                </ul>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>
            <h3 className={classes.cardIconTitle}>
              Estadísticas de los últimos {cantidadDias} días - Cantidad de
              votos: {totalVotos}
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} md>
                <GridContainer>
                  <GridItem xs={12} md>
                    <InputLabel className={classes.label}>
                      Búsqueda por fecha
                    </InputLabel>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} md>
                        <CssTextField
                          fullWidth
                          label="Desde"
                          id="custom-css-outlined-input"
                          value={
                            desde
                              ? new Date(desde * 1000).toLocaleDateString()
                              : ""
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} md>
                        <CssTextField
                          fullWidth
                          label="Hasta"
                          id="custom-css-outlined-input"
                          value={
                            hasta
                              ? new Date(hasta * 1000).toLocaleDateString()
                              : ""
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DateRangeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} md>
                        {!desde || !hasta ? (
                          <Button
                            disabled
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {graficoBar1}
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          {graficoBar2}
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          {graficoBar3}
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          {graficoBar4}
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          {graficoBar5}
        </GridItem>

        {/* MODAL RANGE DATE */}
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          open={rangeModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setRangeModal(false);
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                setRangeModal(false);
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setState([item.selection]);
                handleDate([item.selection.startDate, item.selection.endDate]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => {
                setRangeModal(false);
              }}
              color=""
              style={{ backgroundColor: "rgb(61, 145, 255)" }}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </div>
  );
}
