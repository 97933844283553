import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//Search
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

// @material-ui/icons
import GroupAdd from "@material-ui/icons/GroupAdd";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Check from "@material-ui/icons/Check";
import AddAlert from "@material-ui/icons/AddAlert";

//Authorization
import setAuthToken from "../../utils/setAuthToken";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Danger from "components/Typography/Danger";
import Snackbar from "components/Snackbar/Snackbar.js";

// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function CrearReporte(props) {
  const classes = useStyles();

  //   VARIABLES
  const [textNombre, setTextNombre] = useState("");
  const [textEmpresa, setTextEmpresa] = useState("");
  const [textEmail, setTextEmail] = useState("");
  const [codPrivilegio, setCodPrivilegio] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [error, setError] = useState({});
  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleChange = event => {
    setCodPrivilegio(event.target.value);
  };

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleUserSubmit = async event => {
    event.preventDefault();
    setAuthToken(localStorage.jwtToken);
    let userTypes;
    switch (codPrivilegio) {
      case 1:
        userTypes = "a";
        break;
      case 2:
        userTypes = "b";
        break;
      case 3:
        userTypes = "c";
        break;
      default:
        break;
    }

    const newUserToSave = {
      name: textNombre,
      company: textEmpresa,
      email: textEmail,
      codigoApp: 2,
      nombreApp: "QRRTool",
      codigoPrivilegio: codPrivilegio,
      codigoCategoria: checked,
      userType: userTypes
    };

    await axios
      .post("https://epstool.com/api/users/nuevoUsuario", newUserToSave)
      // .post("http://localhost:5005/api/users/nuevoUsuario", newUserToSave)
      .then(res => {
        setError({});
        setTextNombre("");
        setTextEmpresa("");
        setTextEmail("");
        setCodPrivilegio(null);
        checked.splice(0, 3);
        showNotification();
      })
      .catch(err => {
        setError(err.response.data);
      });
  };

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="success">
              <GroupAdd />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Registrar nuevo usuario</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Escribir Nombre"
                  id="textNombre"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      setTextNombre(event.target.value);
                    },
                    type: "text",
                    value: textNombre
                  }}
                />
                <Danger>
                  {error.name && (
                    <div className="invalid-feedback">{error.name}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Escribir Empresa"
                  id="textEmpresa"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      setTextEmpresa(event.target.value);
                    },
                    type: "text",
                    value: textEmpresa
                  }}
                />
                <Danger>
                  {error.company && (
                    <div className="invalid-feedback">{error.company}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="Escribir correo electrónico"
                  id="textEmail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      setTextEmail(event.target.value);
                    },
                    type: "email",
                    value: textEmail
                  }}
                />
                <Danger>
                  {error.email && (
                    <div className="invalid-feedback">{error.email}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    classes.labelHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  Tipo de Usuario
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <div className={classes.inlineChecks}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={codPrivilegio === "1"}
                        onChange={handleChange}
                        value={1}
                        name="radio button demo"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Supervisor"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={codPrivilegio === "2"}
                        onChange={handleChange}
                        value={2}
                        name="radio button demo"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Administrador"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={codPrivilegio === "3"}
                        onChange={handleChange}
                        value={3}
                        name="radio button demo"
                        aria-label="C"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Propietario"
                  />
                </div>
                <Danger>
                  {error.codigoPrivilegio && (
                    <div className="invalid-feedback">
                      {error.codigoPrivilegio}
                    </div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    classes.labelHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  Categoria
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <div className={classes.inlineChecks}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Infraestructura"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle(2)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Limpieza"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle(3)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Cafetería"
                  />
                </div>
                <Danger>
                  {error.codigoCategoria && (
                    <div className="invalid-feedback">
                      {error.codigoCategoria}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <GridItem justify="flex-end" xs={12}>
              <Button fullWidth onClick={handleUserSubmit} color="success">
                Registrar Ususario
              </Button>
            </GridItem>
          </CardFooter>
        </Card>
      </GridContainer>
      <Snackbar
        place="tc"
        color="success"
        icon={AddAlert}
        message="Usuario almacenado con éxito"
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
      ;
    </div>
  );
}
