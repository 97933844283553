import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  cardTitle,
} from "assets/jss/material-dashboard-pro-react.js";
// import { orange } from "@material-ui/core/colors";

const chartsStyle = {
  cardTitle,
  cardCategory: {
    margin: "0",
    color: grayColor[0],
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  legendTitle: {
    color: grayColor[0],
    margin: "10px 0 !important",
    display: "flex",
  },
  primary: {
    color: primaryColor[0],
  },
  warning: {
    color: warningColor[0],
  },
  nuevo: {
    color: "#EC6B56"
  },
  proceso: {
    color: "#FFC154"
  },
  pausado: {
    color: "#3C4858",
  },
  pausadoPie: {
    color: "#d17905"
  },
  terminado: {
    color: "#45C63F"
  },
  danger: {
    color: dangerColor[0],
  },
  success: {
    color: successColor[0],
  },
  info: {
    color: infoColor[0],
  },
  rose: {
    color: roseColor[0],
  },
  gray: {
    color: grayColor[0],
  },
  cardFooter: {
    display: "block",
  },
};

export default chartsStyle;
